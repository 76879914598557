




























































































.outer_page_content {
  .inner_content_page {
    background-color: white;
    flex: 2;
    position: relative;
  }
}
